import * as React from "react"
import { useState } from "react"
import cx from "classnames"
import { useUpdateEffect } from "react-use"

import Container from "@components/elements/container"
import Card from "@components/elements/card"
import Cta, { CtaProps } from "@components/elements/cta"
import Select from "@components/elements/select"

import styles from "./postsList.module.scss"

interface PostsListProps {
  title?: string
  items: Array<PostProps>
  columns?: number
  cta: CtaProps
  variant?: "light" | "dark"
}

const ALL_CATEGORY = "All"
const ITEMS_PER_PAGE = 6

const calculatePagesCount = totalCount => {
  return Math.ceil(totalCount / ITEMS_PER_PAGE)
}

const PostsList = ({
  title,
  items,
  columns = 2,
  cta,
  variant,
  showFilter = true,
}: PostsListProps) => {
  const itemsNr = items.length

  if (itemsNr === 0) return

  const [itemsToDisplay, setItemsToDisplay] = useState(
    items.slice(0, ITEMS_PER_PAGE)
  )
  const [activeCategory, setActiveCategory] = useState(ALL_CATEGORY)
  const filterCategories = [
    ALL_CATEGORY,
    ...new Set(items.map(item => item.type)),
  ]
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pagesCount: calculatePagesCount(itemsNr),
  })

  const showMore = () => {
    const counter = itemsToDisplay.length + ITEMS_PER_PAGE
    const newItems = items.slice(0, counter)
    const nextPage = pagination.currentPage + 1

    setPagination({
      ...pagination,
      currentPage: nextPage,
    })
    setItemsToDisplay(newItems)
  }

  useUpdateEffect(
    function changeCategory() {
      let filteredItems = items

      if (activeCategory !== ALL_CATEGORY) {
        filteredItems = filteredItems.filter(item => {
          return item.type === activeCategory
        })
      }

      setPagination({
        currentPage: 1,
        pagesCount: calculatePagesCount(filteredItems.length),
      })
      setItemsToDisplay(filteredItems.slice(0, ITEMS_PER_PAGE))
    },
    [activeCategory]
  )

  return (
    <section className={styles.postsList}>
      <Container className={styles.container} isNarrow>
        {title && <h1 className={styles.title}>{title}</h1>}

        {showFilter && (
          <Select
            items={filterCategories}
            activeItem={activeCategory}
            setActiveItem={setActiveCategory}
          />
        )}
        <div className={cx(styles.list, styles[`columns${columns}`])}>
          {itemsToDisplay.map(item => (
            <Card
              {...item}
              key={item.id}
              variant={variant}
              isNarrow={columns > 2}
            />
          ))}
        </div>

        {pagination.pagesCount > 1 &&
          pagination.currentPage < pagination.pagesCount && (
            <Cta
              className={styles.buttonMore}
              text="Show more"
              variant="secondary"
              onClick={showMore}
            />
          )}

        <Cta {...cta} className={styles.buttonCta} variant="light" />
      </Container>
    </section>
  )
}

export default PostsList
