import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"
import Cta, { CtaProps } from "@components/elements/cta"

import styles from "./ctaGallery.module.scss"

const MAX_GALLERY_PICTURES = 4

interface CtaGalleryProps {
  tagline: string
  text: string
  cta: CtaProps
}

const CtaGallery = ({ tagline, text, cta, images }: CtaGalleryProps) => (
  <section className={styles.ctaGallery}>
    <Container className={styles.container}>
      <div className={styles.gallery}>
        {images.slice(0, MAX_GALLERY_PICTURES).map((item, index) => {
          const image = getImage(item.imageData)

          return (
            image && (
              <div className={styles.galleryItem} key={index}>
                <GatsbyImage
                  className={styles.galleryPicture}
                  image={image}
                  alt=""
                />
              </div>
            )
          )
        })}
      </div>
      <div className={styles.content}>
        <div className={styles.tagline}>{tagline}</div>
        {text && <Wysiwyg className={styles.text} contentHtml={text} />}
        <Cta {...cta} className={styles.buttonCta} variant="light" />
      </div>
    </Container>
  </section>
)

export default CtaGallery
