import * as React from "react"
import cx from "classnames"
import parse, { domToReact } from "html-react-parser"

import styles from "./wysiwyg.module.scss"

interface WysiwygProps {
  className?: string
  contentHtml?: string
}

const Wysiwyg = ({ className, contentHtml }: WysiwygProps) => {
  if (!contentHtml) {
    return null
  }

  const options = {
    trim: true,
    replace: ({ attribs, children }: any) => {
      if (!attribs) {
        return
      }

      if (attribs.class === "sub") {
        return <sub>{domToReact(children, options)}</sub>
      }

      if (attribs.class === "sup") {
        return <sup>{domToReact(children, options)}</sup>
      }
    },
  }

  return (
    <div className={cx(styles.wysiwyg, className)}>
      {parse(contentHtml, options)}
    </div>
  )
}

export default Wysiwyg
