import * as React from "react"
import { useState, useCallback, useRef } from "react"
import cx from "classnames"
import { useClickAway } from "react-use"

import PlusIcon from "@images/plus.inline.svg"

import styles from "./select.module.scss"

const Select = ({ items, activeItem, setActiveItem }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hide = useCallback(() => setIsOpen(false), [])
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen])
  const selectRef = useRef(null)

  useClickAway(selectRef, () => {
    isOpen && hide()
  })

  if (items.length <= 2) return

  const selectItem = item => {
    setActiveItem(item)
    hide()
  }

  return (
    <div
      className={cx(styles.select, { [styles.isOpen]: isOpen })}
      ref={selectRef}
    >
      <button className={styles.title} type="button" onClick={toggle}>
        Filter by
        <PlusIcon className={styles.titleIcon} />
      </button>
      <div className={styles.list}>
        {items.map(item => (
          <button
            className={cx(styles.listItem, {
              [styles.isSelected]: item === activeItem,
            })}
            type="button"
            key={item}
            onClick={() => selectItem(item)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Select
