import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import HomeHero from "@components/homeHero"
import CtaList from "@components/ctaList"
import GradientBackground from "@components/elements/gradientBackground"
import CtaGallery from "@components/ctaGallery"
import PostsList from "@components/postsList"
import Footer from "@components/layout/footer"

import linkResolver from "@lib/linkResolver"

const MAX_ARTICLE_TITLE_LENGTH = 75

const IndexPage = ({ data }: any) => {
  const {
    meta_title,
    meta_description,
    meta_image,
    team_cta_text,
    team_cta_link,
    team_images,
    articles_cta_text,
    articles_list,
  } = data.prismicHomepage.data

  const articleItems = articles_list.map(({ article }) => {
    let thumbnailImageData = null
    let hasVideo = false
    let type = article.document.type
    let title = article.document.data.title.text

    if (type === "news_post") {
      type = "news"
    } else if (type === "insight") {
      type = "research"
    }

    for (let el of article.document.data.body) {
      if (el.slice_type === "paragraph") {
        if (el.primary.paragraph_image) {
          thumbnailImageData = el.primary.paragraph_image
        }

        if (
          (el.primary.youtube_link && el.primary.youtube_link.html) ||
          (el.primary.video && el.primary.video.url)
        ) {
          hasVideo = true
        }
      }
    }

    title =
      title.length > MAX_ARTICLE_TITLE_LENGTH
        ? `${title.substring(0, MAX_ARTICLE_TITLE_LENGTH)}...`
        : title

    return {
      id: article.document.id,
      slug: article.document.uid,
      title,
      published: article.document.data.published,
      type,
      postType: type,
      imageData: thumbnailImageData,
      hasVideo,
    }
  })

  return (
    <Layout
      title={meta_title}
      description={meta_description}
      metaImage={meta_image?.url}
      headerLight
      showFooter={false}
    >
      <HomeHero title="THE QUANTUM ALGORITHMS COMPANY" />
      <CtaList
        items={[
          {
            tagline: "Our research",
            text: "Our research has led to fundamental breakthroughs in quantum science, and has made Phasecraft the <b>market leader in quantum IP.</b>",
            cta: {
              text: "Read more",
              url: "/research",
            },
          },
          {
            tagline: "Our Partnerships",
            text: "Phasecraft is the only company that partners with <b>Google, IBM, and Rigetti</b>, developers of world-leading quantum hardware.",
            cta: {
              text: "Learn more",
              url: "/partners",
            },
          },
        ]}
      />
      <GradientBackground>
        <CtaGallery
          tagline="Our team"
          text="<b>Phasecraft is home</b> to some of the world’s leading quantum scientists and engineers."
          cta={{
            text: team_cta_text,
            url: linkResolver()(team_cta_link),
          }}
          images={team_images}
        />
        <PostsList
          columns={3}
          variant="light"
          items={articleItems}
          cta={{
            text: articles_cta_text,
            url: "/news",
          }}
          showFilter={false}
        />
        <Footer isLight />
      </GradientBackground>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    prismicHomepage {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        team_cta_text
        team_cta_link {
          uid
        }
        team_images {
          imageData: image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        articles_cta_text
        articles_list {
          article {
            document {
              ... on PrismicNewsPost {
                id
                type
                uid
                data {
                  title {
                    text
                  }
                  published(formatString: "DD.MM.YY")
                  body {
                    ... on PrismicNewsPostDataBodyParagraph {
                      id
                      primary {
                        paragraph_image {
                          gatsbyImageData(
                            placeholder: BLURRED
                            aspectRatio: 2.14
                          )
                        }
                        youtube_link {
                          html
                        }
                        video {
                          url
                        }
                      }
                      slice_type
                    }
                  }
                }
              }
              ... on PrismicInsight {
                id
                type
                uid
                data {
                  title {
                    text
                  }
                  published(formatString: "DD.MM.YY")
                  body {
                    ... on PrismicInsightDataBodyParagraph {
                      primary {
                        paragraph_image {
                          gatsbyImageData(
                            placeholder: BLURRED
                            aspectRatio: 2.14
                          )
                        }
                      }
                      slice_type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
