import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import cx from "classnames"

import ArrowRight from "@images/arrowRight.inline.svg"
import PlayIcon from "@images/play-icon.inline.svg"

import styles from "./card.module.scss"

export interface PostProps {
  id: string
  slug: string
  title: string
  text?: string
  type: string
  postType: string
  published: string
  imageData: ImageDataLike
  hasVideo?: boolean
  variant?: "light" | "dark"
  isNarrow: boolean
}

const Card = ({
  slug,
  title,
  type,
  text,
  postType,
  published,
  imageData,
  hasVideo,
  variant,
  isNarrow,
}: PostProps) => {
  const image = getImage(imageData)

  return (
    <Link
      className={cx(styles.card, styles[variant], {
        [styles.isNarrow]: isNarrow,
        [styles.noImage]: !image,
        [styles.noHeader]: !(type || published),
      })}
      to={`/${postType}/${slug}`}
    >
      {type && <div className={styles.type}>{type}</div>}
      {published && <div className={styles.date}>{published}</div>}

      {image && (
        <div className={styles.pictureContainer}>
          <GatsbyImage className={styles.picture} image={image} alt="" />
          {hasVideo && <PlayIcon className={styles.pictureIcon} />}
        </div>
      )}

      {title && <h2 className={styles.title}>{title}</h2>}

      {text && <div className={styles.text}>{text}</div>}

      <ArrowRight className={styles.icon} />
    </Link>
  )
}

export default Card
