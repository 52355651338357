import * as React from "react"

import Container from "@components/elements/container"
import BackgroundTexture from "@components/backgroundTexture/index"

import styles from "./homeHero.module.scss"

interface HeroProps {
  title: string
}

const Hero = ({ title }: HeroProps) => (
  <section className={styles.hero}>
    <Container className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
    </Container>

    <div className={styles.background}>
      <BackgroundTexture />
    </div>
  </section>
)

export default Hero
