import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"
import Cta, { CtaProps } from "@components/elements/cta"

import styles from "./ctaList.module.scss"

interface CtaListProps {
  title: string
  items: Array<{
    tagline: string
    text: string
    cta: CtaProps
  }>
}

const CtaList = ({ items }: CtaListProps) => (
  <section className={styles.ctaList}>
    <Container className={styles.container} isNarrow>
      {items.map(item => (
        <div className={styles.item} key={item.tagline}>
          <div className={styles.itemTagline}>{item.tagline}</div>
          {item.text && (
            <Wysiwyg className={styles.itemText} contentHtml={item.text} />
          )}
          <Cta {...item.cta} className={styles.itemCta} />
        </div>
      ))}
    </Container>

    <StaticImage
      className={styles.background}
      src="../images/bg-light-glitch.png"
      placeholder="none"
      alt=""
    />
  </section>
)

export default CtaList
